import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface CustomerState {
    firstName?: string;
    lastName?: string;
    paymentType?: string;
}

// Define the initial state using that type
const initialState: CustomerState = {
    firstName: undefined,
    lastName: undefined,
    paymentType: undefined,
};

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setFirstName: (state, action: PayloadAction<CustomerState>) => {
            state.firstName = action.payload.firstName;
        },
        setLastName: (state, action: PayloadAction<CustomerState>) => {
            state.lastName = action.payload.lastName;
        },
        setPaymentType: (state, action: PayloadAction<CustomerState>) => {
            state.paymentType = action.payload.paymentType;
        },
    },
});

export const { setFirstName, setLastName, setPaymentType } =
    customerSlice.actions;

export default customerSlice.reducer;
