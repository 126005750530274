import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Station from "../models/Station";
import TouSeason from "../models/TouSeason";

// Define a type for the slice state
interface StationState {
    station?: Station;
    todayTouSeason?: TouSeason;
}

// Define the initial state using that type
const initialState: StationState = {
    station: undefined,
    todayTouSeason: undefined,
};

export const stationSlice = createSlice({
    name: "station",
    initialState,
    reducers: {
        setStation: (state, action: PayloadAction<StationState>) => {
            state.station = action.payload.station;
            state.todayTouSeason = action.payload.todayTouSeason;
        },
    },
});

export const { setStation } = stationSlice.actions;

export default stationSlice.reducer;
