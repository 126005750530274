import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import client from "./utils/api";
import App from "./App";
import "./styles/global.sass";
import { Provider } from "react-redux";
import store from "./store";
// import router from './utils/router';
const router = createBrowserRouter([
    {
        path: "*",
        element: <App />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <RouterProvider router={router} />
            </ApolloProvider>
        </Provider>
    </React.StrictMode>,
);
