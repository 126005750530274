import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import axios from "axios";
import { createClient } from "graphql-ws";
import router from "../utils/router";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
});

const logoutLink = onError(({ graphQLErrors }) => {
    if (
        graphQLErrors?.find(
            (error) => error.extensions?.code === "UNAUTHENTICATED",
        )
    ) {
        router.navigate("/login", { replace: true });
    }
});

const authLink = setContext(async (_, { headers }) => {
    let token = localStorage.getItem("otc-token");
    if (!token) {
        throw new Error("Missing otc-token");
    }
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: `ws${
            process.env.REACT_APP_API_ENDPOINT?.includes("https://") ? "s" : ""
        }://${process.env.REACT_APP_API_ENDPOINT?.replace(
            /https?:\/\//,
            "",
        )}}/`,
    }),
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink,
);

const client = new ApolloClient({
    // different Queries hit the same 'station' field (GetStation, GetStationLogs, GetStationPricingHistory,...)
    // those clash and prevent Apollo from caching the response, this solves that issue
    // https://www.apollographql.com/docs/react/caching/advanced-topics/
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    station: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: "Station",
                                id: args?.filter.id,
                            });
                        },
                    },
                },
            },
        },
    }),
    link: authLink.concat(logoutLink).concat(splitLink),
});

export default client;
